$font-family: inherit;

/*Colors*/
$primary: #EE7219;
$light-primary: #FCE3D1;
$extra-light-primary: #FBF1E9;
$secondary: #D1D74B;
$light-secondary: #EDEFB7;
$extra-light-secondary: #F6F7DB;
$info: #398bf7;
$danger: #C00002;
$success: #00B050;
$warning: #F47F32;
$dark: #242a33;
$light: #eaf2fb;
$white: #ffffff;
$gray-100: #FBFCFB !default;
$gray-200: #E3E4E3 !default;
$gray-300: #C9CAC9 !default;
$gray-400: #B1B1B1 !default;
$gray-500: #979897 !default;
$gray-600: #7E7F7E !default;
$gray-700: #656565 !default;
$gray-800: #4C4D4C !default;
$gray-900: #333333 !default;
$black:    #000000 !default;
$rgba-transparent: transparent !default;



//Base
$font-size-base: 0.875rem !default;
$line-height-base: 1.25;


/*Grid*/

$grid-font-size: 0.875rem !default;
$grid-line-height: 1rem !default;

$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 0.5rem !default;

$grid-header-bg: $extra-light-primary !default;
$grid-footer-bg: $white !default;
$grid-alt-bg: $white !default;
$grid-sorted-bg: inherit !default;

$grid-border-width: 0px !default;
$grid-header-border: $extra-light-primary !default;
$grid-border: $extra-light-primary !default;
$grid-cell-horizontal-border-width: 1px !default;
$grid-cell-vertical-border-width: 0px !default;


// Inputs

$kendo-input-text: 0.875rem !default;
$kendo-input-color: #232429 !default;
$kendo-input-border: lighten($primary, 35%);

$kendo-input-padding-y: 0.5rem; 
$kendo-input-padding-x: 1rem;
$kendo-input-line-height: 1.5 !default;
$kendo-input-disabled-color: $kendo-input-color;
$kendo-input-disabled-bg: $gray-100;
$kendo-input-disabled-border: $gray-300;
$kendo-input-disabled-border: $gray-300;
// $kendo-input-hover-border: ;

//Picker
$kendo-picker-text: 0.875rem !default;
$kendo-picker-color: #232429 !default;
$kendo-picker-bg: $white;
$kendo-picker-border: lighten($primary, 35%);
$kendo-picker-gradient: none;

$kendo-picker-hover-color: #232429 !default;;
$kendo-picker-hover-bg: $white;
$kendo-picker-hover-border: lighten($primary, 35%);
$kendo-picker-hover-gradient: none;

$kendo-picker-focus-color: #232429 !default;
$kendo-picker-focus-bg: $white;
$kendo-picker-focus-border: #f7b98c;
$kendo-picker-focus-gradient: none;
$kendo-picker-focus-shadow: 0 0 0 0.25rem rgba(238, 114, 25, 0.25);

//Multiselect
$kendo-multiselect-text: 0.875rem !default;
$kendo-multiselect-color: #232429 !default;
$kendo-multiselect-bg: $white;
$kendo-multiselect-border: lighten($primary, 35%);
$kendo-multiselect-gradient: none;

$kendo-multiselect-hover-color: #232429 !default;;
$kendo-multiselect-hover-bg: $white;
$kendo-multiselect-hover-border: lighten($primary, 35%);
$kendo-multiselect-hover-gradient: none;

$kendo-multiselect-focus-color: #232429 !default;
$kendo-multiselect-focus-bg: $white;
$kendo-multiselect-focus-border: #f7b98c;
$kendo-multiselect-focus-gradient: none;
$kendo-multiselect-focus-shadow: 0 0 0 0.25rem rgba(238, 114, 25, 0.25);




// Buttons

$kendo-button-bg: $gray-100;
$kendo-button-border: $gray-300;
$kendo-button-gradient: none !default;

$kendo-button-hover-bg: $gray-300;
$kendo-button-hover-border: $gray-300;
$kendo-button-hover-gradient: none !default;

$kendo-button-selected-text: $primary;
$kendo-button-selected-bg: $white;
$kendo-button-selected-border: $primary;

$kendo-button-hover-shadow: 0 0 0 0.1rem rgb(201 202 201 / 10%);
$kendo-button-focus-shadow: 0 0 0 0.20rem rgb(201 202 201 / 20%);

//Scheduler

$scheduler-bg: $white;
$scheduler-toolbar-bg: $white;
$scheduler-toolbar-gradient: none !default;
$scheduler-footer-gradient: none !default;
$scheduler-footer-border: $grid-header-border;

// Pager
$pager-item-text: $gray-900 !default;
$pager-item-selected-text: $black !default;
$pager-item-selected-bg: $secondary !default;
$pager-border-width: 0px !default;


//Calendar
$calendar-cell-hover-bg: $gray-200;
$calendar-cell-hover-border: $gray-200;
$calendar-cell-selected-bg: $light-primary;
$calendar-cell-selected-border: $light-primary;
$calendar-cell-selected-hover-bg: $light-primary;
$calendar-cell-selected-hover-border: $light-primary;


//List items
$kendo-list-item-line-height: 1.25rem; 
$kendo-list-item-padding-y: 0.5rem;
$kendo-list-item-padding-x: 1rem;
$kendo-list-item-hover-bg: $gray-100;
$kendo-list-item-selected-bg: $light-primary;

//Popups
$popup-border: $gray-200;



//Menu
$kendo-menu-popup-item-padding-x: 1rem;
$kendo-menu-popup-item-padding-y: 0.5rem;
$kendo-menu-popup-item-padding-x-md: $kendo-menu-popup-item-padding-x !default;
$kendo-menu-popup-item-padding-y-md: $kendo-menu-popup-item-padding-y !default;
$kendo-menu-popup-item-padding-end-md: $kendo-menu-popup-item-padding-y-md !default;
$kendo-menu-popup-item-focus-shadow: inset 0 0 0 0.125rem rgba(200, 200, 200, 0.25);


//Switch
$lg-track-height: 38px !default;


$kendo-switch-off-track-text: $dark;
$kendo-switch-off-track-bg: $gray-200;
$kendo-switch-off-track-border: $gray-200;
$kendo-switch-off-track-gradient: none !default;

$kendo-switch-on-track-text: $dark;
$kendo-switch-on-track-bg: $primary;
$kendo-switch-on-track-border: $primary;
$kendo-switch-on-track-gradient: none !default;

$kendo-switch-off-thumb-text: $dark;
$kendo-switch-off-thumb-bg: $white;
$kendo-switch-off-thumb-border: $dark;
$kendo-switch-off-thumb-gradient: none !default;

$kendo-switch-on-thumb-text: $dark;
$kendo-switch-on-thumb-bg: $white;
$kendo-switch-on-thumb-border: $dark;
$kendo-switch-on-thumb-gradient: none !default;

// Overrides

.k-input-md,
.k-picker-md { 
    line-height: 1.5rem !important; 
}
    .k-input-md .k-input-values, 
    .k-picker-md .k-input-values {
        margin-top:1px;
        display: flex;
    }

.k-rounded-md {
    border-radius: 0.3rem;
}

.k-input-lg,
.k-picker-lg { 
    line-height: 1.5rem !important; 
}

.k-multiselect {
    max-height: 39px;
}

    .k-multiselect .k-input-values.k-chip-list.k-selection-multiple {
        pointer-events: none;
        border: 0 none;
    }

        .k-multiselect .k-chip-actions {
            display: none;;
        }

    
.k-input.k-disabled {
    opacity: 1 !important;
}

.k-input.k-disabled .k-clear-value{
    display: none;
}

.k-column-title {
    font-weight: 700;
}

.k-grid-pager {
    background-color: $gray-100 !important;
}

.k-detail-row {
    background-color: $gray-100 !important;
}

.k-scheduler-datecolumn {
    overflow: hidden !important;
}

.k-master-row .k-i-add:before, 
.k-master-row .k-i-plus:before, 
.k-master-row .k-plus:before {
    content: "\E015";
}

.k-master-row .k-i-kpi-trend-equal:before, 
.k-master-row .k-i-minus:before, 
.k-master-row .k-minus:before {
content: "\e013";
}

.k-switch-lg .k-switch-track {
    height: 36px !important;
}



th.k-header.active .k-grid-column-menu {
    background-color: $light-primary;
}



@import "@progress/kendo-theme-bootstrap/dist/all.scss";
