/*******************/
// Theme Fonts
/********************/
@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300;400;500;600;700;800&display=swap');
$font-family-sans-serif: 'Mukta', sans-serif;
/*******************/
// Theme Colors
/********************/
$primary: #EE7219;
$info: #398bf7;
$danger: #C00002;
$success: #00B050;
$warning: #F47F32;
$dark: #242a33;
$light: #E3E4E3;
$secondary: #D1D74B;

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #FBFCFB !default;
$gray-200: #E3E4E3 !default;
$gray-300: #C9CAC9 !default;
$gray-400: #B1B1B1 !default;
$gray-500: #979897 !default;
$gray-600: #7E7F7E !default;
$gray-700: #656565 !default;
$gray-800: #4C4D4C !default;
$gray-900: #333333 !default;
$black: #000 !default;

$theme-colors: (
  'primary': $primary,
  'medium-primary': #F9C7A3,
  'light-primary': #FCE3D1,
  'very-light-primary': #FBF1E9,
  'extra-light-primary': #FFF8F4,      
  'secondary': $secondary,
  'medium-secondary': #E3E793,
  'light-secondary': #EDEFB7,
  'extra-light-secondary': #F6F7DB,
  'success': $success,
  'light-success': #9CEFD8,
  'info': $info,
  'light-info': #cfecfe,
  'warning': $warning,
  'light-warning': #fff8ec,
  'danger': $danger,
  'light-danger': #F9A3A3,
  'light': $light,
  'dark': $dark,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'gray-500': $gray-500,
  'gray-600': $gray-600,
  'gray-700': $gray-700,
  'gray-800': $gray-700,
  'gray-900': $gray-900,
);

/*******************/
// Theme Text Contrast
/********************/
$min-contrast-ratio: 1.6 !default;
//$enable-shadows: true !default;

/*******************/
// Theme body bg & text color
/********************/
$body-bg: $white !default;
$body-color: #232429 !default;


/*******************/
// Text size
/********************/
$font-size-base: 0.875rem !default;
$line-height-base: 1.25;

// heading fonts setting
$h1-font-size: 36px !default;
$h2-font-size: 24px !default;
$h3-font-size: 21px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;

// Font size
$font-sizes: (
  1: 36px,
  2: 24px,
  3: 21px,
  4: 18px,
  5: 16px,
  6: 14px,
  7: 12px,
);

/*******************/
// Theme common variable
/********************/
$border-radius: 0.3rem !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.05);
$headings-font-weight: 400 !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 600 !default;
$font-weight-base: $font-weight-light !default;

$h5-font-size: $font-size-base * 1.1 !default;
$card-border-width: 0 !default;
$card-box-shadow: $box-shadow !default;
$card-spacer-y: 1.25rem !default;
$card-spacer-x: 1.25rem !default;
$grid-gutter-width: 1.85rem !default;

$input-bg: $white !default;


/*******************/
// Theme badge
/********************/
$badge-font-weight: 300 !default;
$badge-padding-y: 0.35em !default;
$badge-padding-x: 0.7em !default;
$badge-pill-padding-x: 0.7em !default;
$badge-pill-padding-y: 1em !default;
$badge-border-radius: 1rem !default;

// Progress bars
$progress-height: 6px !default;

/*******************/
// Theme btn
/********************/
$btn-border-radius: 21px;
$btn-padding-y: 8px;
$btn-padding-x: 16px;
$btn-font-weight: bold;
$btn-line-height: 20px;

$btn-focus-width: 0px;
$btn-box-shadow: 0 !default;
$btn-focus-box-shadow: 0;

/*******************/
// Theme sidebar width
/********************/
$sidebarWidth: 240px;
$sidebartext: $gray-900;
$sidebartextlight: rgba(30, 42, 53, 0.5);
$miniSidebarWidth: 80px;

$topbarHeight: 52px;

$dropdown-border-width: 0 !default;
$dropdown-box-shadow: $box-shadow !default;
$dropdown-link-hover-bg: $gray-100 !default;

$enable-negative-margins: true;


$table-border-color: #FBF1E9;
$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 0.5rem !default;

/*******************/
// Theme list-group
/********************/


$list-group-color:                  $gray-900;
$list-group-bg:                     $white;
$list-group-border-color:           $gray-300;
$list-group-border-radius:          0;

$list-group-hover-color:            $primary;
$list-group-hover-bg:               $gray-200;
$list-group-active-color:           $primary;
$list-group-active-bg:              $white;
$list-group-active-border-color:    $gray-300;

$list-group-disabled-color:         $gray-600;
$list-group-disabled-hover-color:   $gray-600;
$list-group-disabled-bg:            $gray-100;

$list-group-action-color:           $gray-700;
$list-group-action-hover-color:     $list-group-action-color;

$list-group-action-active-color:    $body-color;
$list-group-action-active-bg:       $white;

$list-group-item-padding-x: 16px;
$list-group-item-padding-y: 16px;


/*******************/
// Theme tab bar
/********************/


$nav-link-padding-y:                0.5rem;
$nav-link-padding-x:                1rem;
$nav-link-font-size:                null;
$nav-link-font-weight:              bold;
$nav-link-color:                    $body-color;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;

$nav-pills-border-radius: 0;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: $white;


/*******************/
// Theme right-leaf
/********************/

$right-leaf-min-width: 45%;
$right-leaf-max-width: 90%;




/*******************/
// Form
/********************/

$input-padding-y: 0.5rem; 
$input-padding-x: 1rem;
$input-line-height: 1.5 !default;
$input-border-color: lighten($primary, 35%);
$input-disabled-bg: #f4f4f4;
$input-disabled-border-color: #E9EAEA;

$form-select-disabled-bg: $gray-100;
$form-select-disabled-border-color: $gray-300;

$input-group-addon-bg: $gray-100;

$form-check-radio-checked-bg-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg width='22px' height='22px' viewBox='0 0 22 22' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eradio_bg%3c/title%3e%3cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='ATOM-/-Form-/-Radio-/-Label-/-Active-Copy' transform='translate(-1.000000, -10.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3e%3cpath d='M12,10 C18.0751322,10 23,14.9248678 23,21 C23,27.0751322 18.0751322,32 12,32 C5.92486775,32 1,27.0751322 1,21 C1,14.9248678 5.92486775,10 12,10 Z M12,14 C8.13400675,14 5,17.1340068 5,21 C5,24.8659932 8.13400675,28 12,28 C15.8659932,28 19,24.8659932 19,21 C19,17.1340068 15.8659932,14 12,14 Z' id='radio_bg'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");


/*******************/
// Modals
/********************/

$modal-sm: 320px;
$modal-md: 520px;
$modal-lg: 820px;
$modal-xl: 1180px;

$modal-padding: 0.5rem 1rem;

$modal-header-padding-y: 0.5rem !default;
