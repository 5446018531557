.customizerSidebar {
  width: $right-leaf-max-width;
  background-color: $white;
  height: calc(100vh - 96px);
  position: fixed;
  right: 0;
  top: 96px;
  z-index: 6;
  transition: 0.2s ease-in;
  margin-right: -$right-leaf-max-width;
  &.showHalfCustomizer {
    margin-right: -45%;
    z-index: 10;
  }
  &.showCustomizer {
    margin-right: 0;
    z-index: 10;
  }
  .scrollerContainer {
    height: 100%;
    
    .scroller {
      height: calc(100vh - 100px);
      overflow-y: auto;
      
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
// .firstPanel, .secondPanel {
//   width: 45vw;
// }

.attacchmentViewer {
  height: calc(100vh - 100px - 72px);
  background-color: $gray-100;
}

.customBtnContainer{
  padding: 0;
} 

.customBtn {
  position: fixed;
  top: 50%;
  -webkit-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  white-space: nowrap;
  border-radius: 5px 5px 0 0;
}
.email-button-tab {
  top: 148px;
  margin-left: -64px;
}
.allegati-button-tab {
  top: 249px;
  margin-left: -71px;
}
.polizza-button-tab {
  top: 354px;
  margin-left: -68px;
}
.diario-button-tab {
  top: 454px;
  margin-left: -65px;
}
.openai-button-tab {
  top: 556px;
  margin-left: -70px;
}
.financials-button-tab {
  top: 669px;
  margin-left: -77px;
}



.table_garanzie_container .k-grid tr:hover {
  background: transparent !important;
}
